<template>
  <BaseCard
    heading="Remote Control"
    icon="mdi-play-protected-content"
    :height="$vuetify.breakpoint.smAndUp ? '514' : ''"
  >
    <DataTableExtended
      ref="table"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      :headers="headers"
      :items="activeConnections"
      :options.sync="options"
      class="elevation-0 border"
      :loading="loading"
      :no-data-text="loading ? 'Loading…' : 'No data available'"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="connectionsCountActive"
      :calculate-widths="true"
      :footerOptions="footerOptions"
      @update:options="fetchTableData(options)"
      :mobile-breakpoint="0"
      :withUrlParams="false"
      data-testid="table-active-remote-control"
    >
      <template v-slot:top>
        <v-row class="pa-3">
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              data-testid="filter-search-active-remote-controls"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.play`]="{ item }">
        <v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              x-small
              :color="Pallette.actionButtons.play"
              v-on="on"
              @click="gotoPlaylist(item)"
              data-testid="btn-open-remote-control"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
          <span>Go to playlist</span>
        </v-tooltip>
        <v-btn
          v-else
          block
          dark
          small
          :color="Pallette.actionButtons.play"
          @click="gotoPlaylist(item)"
          data-testid="btn-open-remote-control"
        >
          <v-icon>mdi-play</v-icon>
          Go to playlist
        </v-btn>
      </template>
      <template v-slot:[`item._engine.name`]="{ item }">
        {{ item._engine.name }}
      </template>
      <template v-slot:[`item.project`]="{ item }">
        {{ item.project }}
      </template>
    </DataTableExtended>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import WsConnMixin from "@/mixins/websocket-connection.js"
import { get } from "lodash"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  name: "RemoteControlWidget",

  components: { DataTableExtended },

  mixins: [GeneralMixin, WsConnMixin],

  data: () => ({
    selected: [],
    headers: [
      {
        text: "",
        value: "play",
        sortable: false,
        wodth: 40,
      },
      {
        text: "Engine",
        align: "left",
        value: "_engine.name",
        sortable: true,
      },
      {
        text: "Project",
        align: "left",
        value: "project",
        sortable: true,
      },
    ],
    loading: false,
    activeConnections: [],
    connectionsCountActive: 0,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    footerOptions: {
      "items-per-page-text": "",
      "items-per-page-options": [5],
    },
    serverPagination: {
      p: 1,
      pp: 5,
      sort: "",
      order: "",
    },
    search: "",
  }),

  mounted: function () {
    if (this.isAuthenticated) {
      this.loading = true
      try {
        this.fetchTableData()
      } catch (err) {
        console.log("Error on fetchTableData", err)
      }
      try {
        this.initWebsocketConnection()
      } catch (err) {
        console.log("Error on initWebsocketConnection", err)
      }
    }
  },

  watch: {
    search() {
      this.fetchTableData(this.$refs?.table?.options)
    },
  },

  methods: {
    fetchTableData() {
      let self = this
      self.getConnections(true, this.$refs?.table?.options).then((res) => {
        self.activeConnections = res.connections
        self.connectionsCountActive = res.count
        self.loading = false
      })
    },
    gotoPlaylist(item) {
      this.$router
        .push({
          path:
            "/engines/remote-control/engine/" +
            item._engine.name +
            "/project/" +
            item.project +
            "/" +
            item._key,
        })
        .catch((err) => {})
    },
    initWebsocketConnection() {
      let self = this
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (get(data, "path", "") == "1.0.ev.refresh.page") {
            self.fetchTableData()
          }
        }
      }
      /* eslint-disable-next-line */
      self.websocket_common_conn.onopen = function (e) {
        // console.log('onopen')
      }
      /* eslint-disable-next-line */
      self.websocket_common_conn.onclose = function (e) {
        // console.error('General socket connection closed unexpectedly')
      }
    },
    getConnections(isActive = false, options) {
      let self = this
      return new Promise((resolve) => {
        if (options) {
          self.serverPagination.sort =
            options.sortBy && options.sortBy[0] ? options.sortBy[0] : "name"
          self.serverPagination.order =
            options.sortDesc && options.sortDesc[0] ? "DESC" : "ASC"
        }

        let params = {
          p: self.options.page,
          pp: self.options.itemsPerPage,
          // keep: '' // @todo define what fields acualy need for webengine interface
        }
        if (self.search !== "") params.search = self.search
        if (self.serverPagination.sort !== "")
          params.sort = self.serverPagination.sort
        if (self.serverPagination.order !== "")
          params.order = self.serverPagination.order
        params.is_active = isActive

        self.$store
          .dispatch("rcPlaylistsEnginesListAction", { params })
          .then(function (response) {
            resolve({
              connections: response.data.data.rc_engines,
              count: response.data.data.full_count,
            })
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    },
  },

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
      isAuthenticated: "isAuthenticated",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
